import { Cloud$BusinessUnitConfig } from '../../types/cloudApi';

export function createQueryParamsFromMatchedPartner(
  matchedPartner?: Cloud$BusinessUnitConfig['matchedPartner'],
) {
  if (matchedPartner && matchedPartner.type === 'AFFILIATE') {
    // Only add the ?partner query param if we matched with an affiliate. This
    // intentionally not `checking matchedPartner.config.theming_enabled`
    // because Breeze will figure out if we should show theme or not.
    return {
      partner: encodeURIComponent(matchedPartner.config.utm_sources[0]),
    };
  }

  return {};
}
