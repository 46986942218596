import { useReducer } from 'react';
import { useIntl } from 'react-intl';

import { FREE_TEXT_ADDRESS_SEARCH_ENABLED } from '../../../../config/featureToggles/constants';
import { isEnabled } from '../../../../config/featureToggles/utils';
import { useGlobalConfig } from '../../../../hooks/configContext';
import { Cloud$SuggestedAddress } from '../../../../types/cloudApi';

import { BusinessUnitConfig } from '../../../../cloudConfig/cloudConfig';
import { getMessageForFetchError } from '../ErrorHandling/errorMessageHelpers';
import { validateOnClient, validateOnServer } from './addressValidationUtils';
import { ValidationState, reducer } from './reducer';
import { DropLast } from './typeUtils';

type Options = {
  freeTextSearchEnabled?: boolean;
  buSlug: BusinessUnitConfig['slug'];
  market: BusinessUnitConfig['market'];
};

async function validateAddress(
  inputAddress: Cloud$SuggestedAddress,
  suggestions: Cloud$SuggestedAddress[] | null,
  target: 'client' | 'server' = 'client',
  options: Options,
) {
  const clientValidatedAddress = validateOnClient(
    inputAddress,
    suggestions,
    !!options.freeTextSearchEnabled,
  );

  if (target !== 'server') {
    return clientValidatedAddress;
  }

  return validateOnServer(
    clientValidatedAddress,
    options.buSlug,
    options.market,
  );
}

type ValidationActions = {
  resetError: () => void;
  validate: (
    // The validation function have the same arguments as `validateAddress()`,
    // except from the last argument `options`
    ...args: DropLast<Parameters<typeof validateAddress>>
  ) => Promise<Cloud$SuggestedAddress | void>;
};

export function useAddressValidation(): {
  state: ValidationState;
  actions: ValidationActions;
} {
  const intl = useIntl();
  const config = useGlobalConfig();

  const [state, dispatch] = useReducer(reducer, {
    status: 'idle',
    errorMessage: null,
  });

  const validate = async (
    addressToValidate: Cloud$SuggestedAddress,
    suggestions: Cloud$SuggestedAddress[] | null,
    target: 'client' | 'server' = 'client',
  ) => {
    dispatch({ type: 'IS_VALIDATING' });

    const options = {
      freeTextSearchEnabled: isEnabled(
        FREE_TEXT_ADDRESS_SEARCH_ENABLED,
        config,
      ),
      buSlug: config.BU_CONFIG.slug,
      market: config.BU_CONFIG.market,
    };

    try {
      const validAddress = await validateAddress(
        addressToValidate,
        suggestions,
        target,
        options,
      );
      dispatch({
        type: 'VALIDATION_SUCCESS',
      });
      return validAddress;
    } catch (error) {
      dispatch({
        type: 'VALIDATION_FAILED',
        errorMessage: intl.formatMessage(getMessageForFetchError(error)),
      });
      return null;
    }
  };

  function resetError() {
    dispatch({ type: 'RESET_ERROR' });
  }

  const actions = {
    validate,
    resetError,
  };

  return { state, actions };
}
