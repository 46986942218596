import { useTheme } from '@emotion/react';
import { Combobox } from '@headlessui/react';
import { useRouter } from 'next/router';
import { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import dynamic from 'next/dynamic';
import { ADD_CONSUMPTION_FEATURE_NAME } from '../../../config/featureToggles/constants';
import { useIntersectionObserver } from '../../../hooks/useIntersectionObserver';
import MapPin from '../../../images/icons/feather/map-pin.svg';
import { logUIEvent } from '../../../lib/analytics/analytics';
import {
  Cloud$Interest,
  Cloud$SuggestedAddress,
} from '../../../types/cloudApi';
import getFirstFromArray from '../../../utils/getFirstFromArray';

import messages from '../../../../../breeze/components/Interest/messages';
import { useGlobalConfig } from '../../../hooks/configContext';
import ArrowRightSvg from '../../../images/icons/arrow-right.svg';
import { logToSentry } from '../../../lib/sentry';
import Button from '../../../rainbow/Button/Button';
import { Cloud$MainHardwareType } from '../../../types/cloudEnums';
import { Otovo$Locale } from '../../../types/otovoweb';
import IfEnabled from '../../FeatureToggles/IfEnabled';
import AddressSearchForm, { SubmissionMethod } from './AddressSearchForm';
import { validateOnServer } from './AddressValidation/addressValidationUtils';
import { useAddressValidation } from './AddressValidation/useAddressValidation';
import ErrorMessages from './ErrorHandling/ErrorMessages';
import StyledAddressInput from './StyledAddressInput';
import StyledAddressSuggestions from './StyledAddressSuggestions';
import { useAddressSuggestions } from './useAddressSuggestions';
import useCreateInterest from './useCreateInterest';

const ConsumptionSlider = dynamic(
  () => import('../../ConsumptionSlider/ConsumptionSlider'),
);

type Props = {
  onInterestCreated: (interest: Cloud$Interest) => void;
  onAddressesShown?: (state: 'open' | 'closed') => void;
  submissionMethod: SubmissionMethod;
  locale?: Otovo$Locale;
  experimentVersion?: string;
  mainHardwareType?: Cloud$MainHardwareType;
};

const AddressSearchBar = ({
  onInterestCreated,
  onAddressesShown,
  submissionMethod,
  locale,
  experimentVersion,
  mainHardwareType,
}: Props) => {
  const intl = useIntl();
  const theme = useTheme();
  const config = useGlobalConfig();
  const { query: routerQuery } = useRouter();
  const [inputRef, isInViewPort] = useIntersectionObserver<HTMLInputElement>();
  const [submitError, setSubmitError] = useState<string>('');
  const [shouldSpinForever, setShouldSpinForever] = useState(false);

  /**
   * Custom hooks
   * */
  const { createInterest, isCreatingInterest } = useCreateInterest();

  const {
    address,
    setAddress,
    suggestions,
    isLoadingSuggestions,
    suggestError,
  } = useAddressSuggestions(locale, mainHardwareType);

  const { state: validationState, actions: validationActions } =
    useAddressValidation();

  /**
   * Derived fields
   * */
  const error = suggestError || submitError || validationState.errorMessage;
  const isLoading =
    isLoadingSuggestions ||
    isCreatingInterest ||
    shouldSpinForever ||
    validationState.status === 'pending';

  const handleOnChange = (newQuery: string) => {
    // reset any errors on key change
    setSubmitError('');
    validationActions.resetError();

    logUIEvent(
      {
        action: 'User started typing an address',
      },
      { once: true, broadcastToParent: true },
    );

    setAddress({
      text: newQuery,
      id: '',
    });
  };

  useEffect(() => {
    if (window.addressInput) {
      handleOnChange(window.addressInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const address = getFirstFromArray(routerQuery.address || '');
    if (!address) return;

    validateOnServer(
      { id: '', text: address },
      config.BU_CONFIG.slug,
      config.BU_CONFIG.market,
    )
      .then((validAddress) => {
        if (validAddress.id) {
          setAddress(validAddress);
        }
      })
      .catch((error) => {
        logToSentry('Unable to validate address from query parameter', {
          extra: { market: config.BU_CONFIG.market, address, error },
        });
      });
  }, [config.BU_CONFIG.market, config.BU_CONFIG.slug, routerQuery, setAddress]);

  /**
   * Effects
   * */
  useEffect(() => {
    if (isInViewPort && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, isInViewPort]);

  /**
   * Event handlers
   * */
  const handleError = (errorMessage: string) => {
    setSubmitError(errorMessage);
  };

  const handleOnSuccess = (interest?: Cloud$Interest) => {
    setShouldSpinForever(true);

    if (interest) {
      onInterestCreated(interest);
    }
  };

  const handleSelectAddress = async (
    selectedAddress: Cloud$SuggestedAddress | null,
  ) => {
    if (!selectedAddress) {
      return;
    }

    setAddress(selectedAddress);

    if (submissionMethod === 'nativeForm') {
      // When using the nativeForm type, we need validate the address before
      // creating the interest.
      await validationActions.validate(selectedAddress, suggestions, 'server');
    }
  };

  const isBatterySalesFlow = mainHardwareType === 'battery_system';
  const placeholderText = isBatterySalesFlow
    ? intl.formatMessage(messages.inexactAddressLabel)
    : intl.formatMessage(messages.addressLabel);

  return (
    <div className="relative h-10">
      <AddressSearchForm
        address={address}
        createInterest={createInterest}
        onError={handleError}
        onSuccess={handleOnSuccess}
        submissionMethod={submissionMethod}
        setAddress={setAddress}
        suggestions={suggestions}
        validateAddress={validationActions.validate}
        experimentVersion={experimentVersion}
        mainHardwareType={mainHardwareType}
      >
        <Combobox value={address} onChange={handleSelectAddress}>
          {({ open }) => {
            if (onAddressesShown) {
              onAddressesShown(open ? 'open' : 'closed');
            }
            return (
              <>
                <div className="relative flex h-full w-full items-center">
                  <div className="absolute left-3 hidden sm:block">
                    <MapPin
                      className="h-7 w-7"
                      color={theme.alias.border_100 || 'currentColor'}
                    />
                  </div>

                  <StyledAddressInput
                    type="search"
                    name="address-autocomplete"
                    ref={inputRef}
                    displayValue={(selectedAddress: Cloud$SuggestedAddress) =>
                      selectedAddress.text
                    }
                    placeholder={placeholderText}
                    onChange={(event: FormEvent<HTMLInputElement>) => {
                      const newQuery = event.currentTarget.value;
                      handleOnChange(newQuery);
                    }}
                    open={open && !!suggestions?.length}
                  />
                  <script
                    type="text/javascript"
                    dangerouslySetInnerHTML={{
                      __html: `
                      document.querySelectorAll('[name="address-autocomplete"]').forEach(function (elem) {
                        elem.addEventListener('keyup', function (evt) {
                          window.addressInput = evt.currentTarget.value;
                        })
                      });
                    `,
                    }}
                  />

                  <Button
                    className="absolute right-3"
                    shape="circle"
                    variant="blue"
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading}
                  >
                    <ArrowRightSvg data-testid="arrowRightSvg" />
                  </Button>
                </div>
                {suggestions && !error && (
                  <StyledAddressSuggestions
                    suggestions={suggestions}
                    query={address.text || ''}
                  />
                )}
                <ErrorMessages error={error} />
                <IfEnabled featureToggleName={ADD_CONSUMPTION_FEATURE_NAME}>
                  <div className="pt-4">
                    <ConsumptionSlider
                      id="monthly_energy_bill"
                      min={150}
                      max={5000}
                      initial={500}
                      hidden={!!error}
                      theme={getFirstFromArray(routerQuery.theme)}
                    />
                  </div>
                </IfEnabled>
              </>
            );
          }}
        </Combobox>
      </AddressSearchForm>
    </div>
  );
};

export default AddressSearchBar;
