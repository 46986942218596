export default function appendAndClickLink(href: string) {
  const redirectLink = document.createElement('a');

  redirectLink.setAttribute('href', href);
  document.body.appendChild(redirectLink);

  redirectLink.dispatchEvent(
    new MouseEvent('mousedown', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
  redirectLink.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  );
}
