import { useEffect, useRef, useState } from 'react';
import { observe } from '../lib/intersectionObserver';

export function useIntersectionObserver<ElementType>(): [
  { current: null | ElementType },
  boolean,
] {
  const ref: { current: null | ElementType } = useRef(null);
  const [isInViewPort, setIsInViewPort] = useState(false);

  useEffect(() => {
    observe({
      // @ts-expect-error 2322
      element: ref.current,
      isIntersectingCallback: () => {
        setIsInViewPort(true);
      },
    });
  }, []);

  return [ref, isInViewPort];
}
