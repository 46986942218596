type IdleOrPendingState = {
  status: 'pending' | 'idle';
  errorMessage: string | null;
};

type ValidState = {
  status: 'valid';
  errorMessage: null;
};

type InvalidState = {
  status: 'invalid';
  errorMessage: string;
};

export type ValidationState = IdleOrPendingState | ValidState | InvalidState;

type Action =
  | { type: 'IS_VALIDATING' }
  | { type: 'RESET_ERROR' }
  | { type: 'VALIDATION_SUCCESS' }
  | { type: 'VALIDATION_FAILED'; errorMessage: string };

export function reducer(
  state: ValidationState,
  action: Action,
): ValidationState {
  switch (action.type) {
    case 'IS_VALIDATING': {
      return {
        errorMessage: null,
        status: 'pending',
      };
    }

    case 'RESET_ERROR': {
      return {
        errorMessage: null,
        status: 'idle',
      };
    }
    case 'VALIDATION_SUCCESS':
      return {
        errorMessage: null,
        status: 'valid',
      };
    case 'VALIDATION_FAILED':
      return {
        errorMessage: action.errorMessage,
        status: 'invalid',
      };
    default:
      throw new Error('Invalid action type for useAddressValidation reducer.');
  }
}
