import { stripLeadingSlash, stripTrailingSlash } from '../../lib/urlUtil';

function getHref(
  nextPath: string,
  baseUrl?: string | null,
  locale?: string | null,
): string {
  // TODO: Remove when Next router has been patched.
  // Next router sometimes sets asPath to '//' for index pages.
  // Get rid of any repeated trailing slash paths.
  const path = nextPath.replace(/\/+/g, '/');

  if (!baseUrl || typeof baseUrl !== 'string') {
    return path;
  }

  if (path === '/') {
    if (locale) {
      return `${baseUrl}/${locale}/`;
    }
    return `${baseUrl}${path}`;
  }

  return [stripTrailingSlash(baseUrl), locale, stripLeadingSlash(path)]
    .filter(Boolean)
    .join('/');
}

export function getCanonicalUrl(
  nextPath: string,
  baseUrl: string,
  locale: string | null,
): string | null {
  return baseUrl && typeof baseUrl === 'string'
    ? getHref(nextPath, baseUrl, locale)
    : null;
}
