import { ComboboxOption, ComboboxOptions } from '@headlessui/react';
import cx from 'classnames';

type Props = { error: string | null };

const ErrorMessages = ({ error }: Props) => {
  if (!error) {
    return null;
  }

  return (
    <ComboboxOptions
      as="ul"
      static
      className={cx(
        'absolute mt-1 w-full overflow-auto rounded-lg bg-white',
        'z-50 text-lg shadow-lg',
      )}
    >
      <ComboboxOption
        as="li"
        disabled
        key={error}
        value={error}
        className="relative z-50 cursor-default select-none bg-peach-5 px-4 py-3 text-sm text-peach-90 ring-2"
      >
        <span className="block">{error}</span>
      </ComboboxOption>
    </ComboboxOptions>
  );
};

export default ErrorMessages;
