import { Cloud$Address, Cloud$Interest } from '../../types/cloudApi';

import storage from '../../lib/localStorage';

const STORAGE_KEY = 'stored-interest';

type StoredInterest = {
  interestId: string;
  address: Cloud$Address;
};

// Keep the welcome back two weeks
const EXPIRY_DAYS = 14;

export function storeInterest(interest: Cloud$Interest) {
  const expireDate = new Date(
    new Date().getTime() + EXPIRY_DAYS * 24 * 3600 * 1000,
  );
  storage.setItem(
    STORAGE_KEY,
    {
      interestId: interest.id,
      address: interest.address,
    },
    expireDate.getTime(),
  );
}

export function getStoredInterest(): StoredInterest | null | undefined {
  return storage.getItem(STORAGE_KEY);
}

export function clearStoredInterest() {
  storage.removeItem(STORAGE_KEY);
}
