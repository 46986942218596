import cx from 'classnames';

type Props = {
  size?: string;
  color?: string;
  className?: string;
};

const Spinner = ({ size, color, className }: Props) => {
  return (
    <svg
      className={cx(
        size || 'h-32 w-32',
        color || 'text-peach-40',
        className,
        'motion-safe:animate-spin',
      )}
      viewBox="0 0 36 36"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_248_2702)">
        <path
          d="M18.7488 0.5H16.2932V10.3296H18.7488V0.5Z"
          fill="currentColor"
        />
        <path
          opacity="0.05"
          d="M30.7491 6.49796L29.0127 4.75842L22.0747 11.709L23.8111 13.4485L30.7491 6.49796Z"
          fill="currentColor"
        />
        <path
          opacity="0.1"
          d="M35 19.2418V16.7817H25.1882V19.2418H35Z"
          fill="currentColor"
        />
        <path
          opacity="0.24"
          d="M29.0121 31.2622L30.7485 29.5226L23.8106 22.5721L22.0742 24.3116L29.0121 31.2622Z"
          fill="currentColor"
        />
        <path
          opacity="0.4"
          d="M18.7488 25.6704H16.2932V35.5H18.7488V25.6704Z"
          fill="currentColor"
        />
        <path
          opacity="0.88"
          d="M4.25092 6.49796L5.9873 4.75842L12.9253 11.709L11.1889 13.4485L4.25092 6.49796Z"
          fill="currentColor"
        />
        <path
          opacity="0.75"
          d="M0 19.2418L0 16.7817H9.81178V19.2418H0Z"
          fill="currentColor"
        />
        <path
          opacity="0.6"
          d="M5.98785 31.2622L4.25146 29.5226L11.1894 22.5721L12.9258 24.3116L5.98785 31.2622Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_248_2702">
          <rect
            width="35"
            height="35"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Spinner;
