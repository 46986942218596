import styled from '@emotion/styled';
import { Combobox } from '@headlessui/react';
import { ComponentType } from 'react';

const StyledAddressInput: ComponentType<any> = styled(Combobox.Input)(({
  theme,
  open,
}) => {
  return {
    '--tw-ring-color': `${theme.alias.border_100} !important`,
    appearance: 'none',
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale',
    width: '100%',
    padding: `20px ${theme.space[8]} 20px ${theme.space[4]}`,
    outline: 'none',
    border: 'none',
    borderRadius: theme.alias.radii.radius_200,
    borderBottomLeftRadius: open ? 0 : undefined,
    borderBottomRightRadius: open ? 0 : undefined,
    boxShadow: `0 1px 2px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.1)`,
    backgroundColor: theme.alias.background_100,
    color: theme.colors.text_heading,
    letterSpacing: theme.letterSpacings.s,
    fontSize: `${theme.fontSizes[3]} !important`,
    fontFamily: theme.fonts.sansSerif,
    lineHeight: '1',
    transition: 'all 0.2s ease-out',

    '::placeholder': {
      color: theme.alias.text_200,
      opacity: 1, // Firefox fix
    },

    '::-webkit-input-placeholder': {
      // Fix mobile Safari problem where placeholder isn't centered
      lineHeight: 'normal !important',
    },

    ':focus': {
      backgroundColor: theme.alias.background_200,
    },

    ':disabled': {
      cursor: 'not-allowed',
    },

    '@media (min-width:640px)': {
      padding: `20px ${theme.space[7]}`,
      fontSize: `${theme.fontSizes[5]} !important`,
    },

    '@media (min-width:800px)': {
      padding: `20px ${theme.space[8]}`,
      fontSize: `${theme.fontSizes[6]} !important`,
    },
  };
});

export default StyledAddressInput;
