import { AddressNotFoundError } from './AddressNotFoundError';
import { AreaTooBigError } from './AreaTooBigError';

export function handleResponseErrors(response: Response) {
  if (response.status === 422) {
    throw new AreaTooBigError(response.statusText, response.status);
  }
  if (response.status === 400) {
    throw new AddressNotFoundError(response.statusText, response.status);
  }
  if (!response.ok) {
    throw new Error(response.statusText);
  }
}
