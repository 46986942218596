import { defineMessages } from 'react-intl';

const I18N_NAMESPACE = 'InterestRegistration';

export default defineMessages({
  addressLabel: {
    id: `${I18N_NAMESPACE}.addressLabel`,
    defaultMessage: 'Search address e.g., 10 Downing Street',
  },

  inexactAddressLabel: {
    id: `${I18N_NAMESPACE}.inexactAddressLabel`,
    defaultMessage: 'Search zipcode e.g, SW1A 2AA',
  },

  addressSuggestError: {
    id: `${I18N_NAMESPACE}.addressSuggestError`,
    defaultMessage:
      'Sorry! We seem to have a problem with our address search. Please try again later.',
  },

  outsideServiceArea: {
    id: `${I18N_NAMESPACE}.outsideServiceArea`,
    defaultMessage: 'The address is outside our service area',
  },

  outsideServiceAreaDescription: {
    id: `${I18N_NAMESPACE}.outsideServiceAreaDescription`,
    defaultMessage:
      'Unfortunately, Otovo does not deliver the requested products to your area. We are working on expanding our coverage, and we promise to tell you as soon as we open up.',
  },

  tooSmallInstallation: {
    id: `${I18N_NAMESPACE}.tooSmallInstallation`,
    defaultMessage: 'The roof is too small',
  },

  tooSmallInstallationDescription: {
    id: `${I18N_NAMESPACE}.tooSmallInstallationDescription`,
    defaultMessage:
      'Our systems claim your roof is too small to fit solar panels. Please contact us at {email} if you believe this is an error.',
  },

  tryDifferentAddress: {
    id: `${I18N_NAMESPACE}.tryDifferentAddress`,
    defaultMessage: 'Click here to try a different address',
  },

  somethingWentWrong: {
    id: `${I18N_NAMESPACE}.somethingWentWrong`,
    defaultMessage: 'Something went wrong!',
  },

  calculationFailed: {
    id: `${I18N_NAMESPACE}.calculationFailed`,
    defaultMessage: 'Calculation failed',
  },

  installationTooSmall: {
    id: `${I18N_NAMESPACE}.installationTooSmall`,
    defaultMessage:
      'Your roof is too small to generate an automated price estimate. Please contact us at {email} if you believe this is an error.',
  },

  installationTooLarge: {
    id: `${I18N_NAMESPACE}.installationTooLarge`,
    defaultMessage:
      'Your roof is unfortunately too big to generate an automated price estimate. Please contact us at {email} if you believe this is an error.',
  },

  dontLetThisStopYou: {
    id: `${I18N_NAMESPACE}.dontLetThisStopYou`,
    defaultMessage:
      'Please do not allow this error to stop you on the way of solar power production on your roof.',
  },

  manualHandlingParagraph: {
    id: `${I18N_NAMESPACE}.manualHandlingParagraph`,
    defaultMessage:
      'To enable calculation for your roof, you can send us your address and e-mail address via {viaChat} at otovo.no or at {wordEmail}, and we will prepare a manual report for you.',
  },

  manualHandlingParagraphWhenChatIsDisabled: {
    id: `${I18N_NAMESPACE}.manualHandlingParagraphWhenChatIsDisabled`,
    defaultMessage:
      'To receive a calculation of your roof you can send us your address and your email to {wordEmail}, then we will make a manual report for you.',
  },

  wordEmail: {
    id: `${I18N_NAMESPACE}.wordEmail`,
    defaultMessage: 'email',
  },

  // Pre-pinning messages:
  searchingForYourAddress: {
    id: `${I18N_NAMESPACE}.searchingForYourAddress`,
    defaultMessage: 'Searching for your address',
  },

  solarPotentialInYourArea: {
    id: `${I18N_NAMESPACE}.solarPotentialInYourArea`,
    defaultMessage: 'Checking the solar potential in your area',
  },

  findingAvailableInstallers: {
    id: `${I18N_NAMESPACE}.findingAvailableInstallers`,
    defaultMessage: 'Connecting you to available installers',
  },

  timeoutErrorMessage: {
    id: `${I18N_NAMESPACE}.timeoutErrorMessage`,
    defaultMessage: 'This took awfully long ...',
  },

  // Post-pinning messages

  installersAreCompeting: {
    id: `${I18N_NAMESPACE}.installersAreCompeting`,
    defaultMessage: '{value} installers are currently bidding for your project',
  },

  findingBestProducts: {
    id: `${I18N_NAMESPACE}.findingBestProducts`,
    defaultMessage: 'Finding the best products for your roof',
  },

  checkingSavings: {
    id: `${I18N_NAMESPACE}.checkingSavings`,
    defaultMessage: 'Calculating your savings potential',
  },

  preparingPriceEstimate: {
    id: `${I18N_NAMESPACE}.preparingPriceEstimate`,
    defaultMessage: 'Preparing a price estimate',
  },

  searchHeader: {
    id: `${I18N_NAMESPACE}.searchHeader`,
    defaultMessage: 'Find out how much you can save',
  },

  areaOfInterestTooLarge: {
    id: `${I18N_NAMESPACE}.areaOfInterestTooLarge`,
    defaultMessage: `Sorry! This address covers too large an area. Please search for a smaller address so we can give you a good price estimate.`,
  },

  addressNotFound: {
    id: `${I18N_NAMESPACE}.addressNotFound`,
    defaultMessage: `Sorry! We could not find the address. Please try rewriting the address or city name again.`,
  },
});
