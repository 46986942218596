let _intersectionObserver: IntersectionObserver;

const isIntersectionObserverSupported = () => {
  return (
    __isBrowser__ &&
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  );
};

type ObserverEntryTarget = {
  isIntersectingCallback: (elem: IntersectionObserverEntry) => void;
  isNotIntersectingCallback: (elem: IntersectionObserverEntry) => void;
  doUnobserve: boolean;
} & HTMLElement;

const init = () => {
  _intersectionObserver = new IntersectionObserver(
    (entries, self) => {
      entries.forEach((entry) => {
        // @ts-expect-error 2322
        const _target: ObserverEntryTarget = entry.target;
        if (entry.intersectionRatio > 0) {
          _target.isIntersectingCallback(entry);
          if (_target.doUnobserve) {
            self.unobserve(_target);
          }
        } else if (_target.isNotIntersectingCallback) {
          _target.isNotIntersectingCallback(entry);
        }
      });
    },
    {
      rootMargin: '100px 0px 0px 0px', // load resource as it enters the viewport
    },
  );
};

export const observe = ({
  element,
  isIntersectingCallback,
  isNotIntersectingCallback,
  doUnobserve = true,
}: {
  element: null | HTMLElement;
  isIntersectingCallback: any;
  isNotIntersectingCallback?: any;
  doUnobserve?: boolean;
}) => {
  if (!element) {
    return;
  }
  if (!isIntersectionObserverSupported()) {
    isIntersectingCallback();
    return;
  }
  if (!_intersectionObserver) {
    init();
  }

  // @ts-expect-error 2339
  element.isIntersectingCallback = isIntersectingCallback;
  // @ts-expect-error 2339
  element.isNotIntersectingCallback = isNotIntersectingCallback;
  // @ts-expect-error 2339
  element.doUnobserve = doUnobserve;
  _intersectionObserver.observe(element);
};

export const unobserve = ({ element }: { element: null | HTMLElement }) => {
  if (!element) {
    return;
  }
  if (!isIntersectionObserverSupported()) {
    return;
  }
  if (!_intersectionObserver) {
    throw new Error(
      'Cannot unobserve because Intersection Observer is not initialised',
    );
  }
  _intersectionObserver.unobserve(element);
};
