import { useState } from 'react';
import { useIntl } from 'react-intl';
import useSWR from 'swr';

import { useGlobalConfig } from '../../../hooks/configContext';
import useDebounce from '../../../hooks/useDebounce';
import { Cloud$SuggestedAddress } from '../../../types/cloudApi';
import { Otovo$Locale } from '../../../types/otovoweb';
import { Cloud$MainHardwareType } from '../../../types/cloudEnums';

import messages from '../../../../../breeze/components/Interest/messages';
import {
  MIN_CHARS_FOR_SENDING_REQUEST,
  MIN_CHARS_FOR_SENDING_REQUEST_BATTERY,
} from './constants';

type AddressSuggestions = {
  query: string;
  suggestions: Array<Cloud$SuggestedAddress>;
};

type ReturnType = {
  suggestions: Array<Cloud$SuggestedAddress> | null;
  suggestError: string;
  isLoadingSuggestions: boolean;
  address: Cloud$SuggestedAddress;
  setAddress: (address: Cloud$SuggestedAddress) => void;
};

const fetcher = (url: string) =>
  fetch(url).then((res) => {
    if (!res.ok) {
      throw new Error(
        `A HTTP<${res.status}> error occurred while fetching address suggestions.`,
      );
    }
    return res.json();
  });

const MIN_CHARS_FOR_FAST_DEBOUNCE = 10;
const FAST_DEBOUNCE = 500;
const SLOW_DEBOUNCE = 1000;

export function useAddressSuggestions(
  locale?: Otovo$Locale,
  mainHardwareType?: Cloud$MainHardwareType,
): ReturnType {
  const [address, setAddress] = useState<Cloud$SuggestedAddress>({
    text: '',
    id: '',
  });
  function getDebounceDelay() {
    if (address.text.length < MIN_CHARS_FOR_FAST_DEBOUNCE) {
      return SLOW_DEBOUNCE;
    }

    return FAST_DEBOUNCE;
  }

  const debounceDelay = getDebounceDelay();
  const debouncedAddress = useDebounce(address, debounceDelay);

  const intl = useIntl();
  const { BU_CONFIG } = useGlobalConfig();

  const minCharsForSendingRequest =
    mainHardwareType === 'battery_system'
      ? MIN_CHARS_FOR_SENDING_REQUEST_BATTERY
      : MIN_CHARS_FOR_SENDING_REQUEST;

  const shouldFetch =
    debouncedAddress.text.length >= minCharsForSendingRequest &&
    !debouncedAddress.id; // We already have a valid ID if it has an id—no need to fetch suggestions

  const baseUrl = new URL(process.env.NEXT_PUBLIC_OTOVOAPI_URL);
  baseUrl.pathname = '/web/location/address/suggest/';
  baseUrl.searchParams.append('query', debouncedAddress.text);
  baseUrl.searchParams.append('country', BU_CONFIG.market);
  if (locale) {
    baseUrl.searchParams.append('locale', locale);
  }
  if (mainHardwareType) {
    baseUrl.searchParams.append('main_hardware_type', mainHardwareType);
  }

  const { data, error, isValidating } = useSWR<AddressSuggestions>(
    shouldFetch ? baseUrl.toString() : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      keepPreviousData: shouldFetch,
    },
  );

  if (error) {
    return {
      address,
      setAddress,
      suggestions: null,
      suggestError: intl.formatMessage(messages.addressSuggestError),
      isLoadingSuggestions: isValidating,
    };
  }

  return {
    address,
    setAddress,
    suggestions: data?.suggestions || [],
    suggestError: '',
    isLoadingSuggestions: isValidating,
  };
}
