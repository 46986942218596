import sanityClient from 'picosanity';
import throwForFalsy from '../throwForFalsy';

type SanityClientConfig = {
  projectId: string;
  dataset: string;
  useCdn: boolean;
};

export function getSanityClientConfig(): SanityClientConfig {
  return {
    projectId: throwForFalsy(process.env.NEXT_PUBLIC_SANITY_PROJECT_ID),
    dataset: throwForFalsy(process.env.NEXT_PUBLIC_SANITY_DATASET),
    useCdn: process.env.NODE_ENV === 'production',
  };
}

export function getSanityClient() {
  return sanityClient(getSanityClientConfig());
}
