import Image, { ImageProps } from 'next/image';
import { useNextSanityImage } from 'next-sanity-image';
import { cn } from '../../lib/classNames';
import { Sanity$Image } from '../../types/sanityImage';
import { Sanity$FloatingImage } from '../../types/sanityTypes';
import { getSanityClient } from '../../lib/sanity/sanityClient';

const SanityImage = ({
  image,
  alt,
  fill = false,
  equalizeImageHeight = false,
  maxHeightFull = false,
  className,
  objectPosition = 'left',
  ...rest
}: {
  image: Sanity$Image | Sanity$FloatingImage;
  alt?: string;
  fill?: boolean;
  equalizeImageHeight?: boolean;
  maxHeightFull?: boolean;
  objectPosition?: string;
} & Omit<ImageProps, 'src' | 'alt'>) => {
  const imageProps = useNextSanityImage(getSanityClient(), image);

  // Prioritize the alt text set on the Sanity image over the one passed as prop
  const altText = image?.alt || alt || '';

  const objectFit = equalizeImageHeight ? 'object-cover' : 'object-contain';
  const heightClass = maxHeightFull ? 'max-h-full' : 'max-h-[500px]';

  if (fill) {
    return (
      <Image
        src={imageProps.src}
        // The loader prop ensures that images are optimized using Sanity CDN
        // instead of Vercel CDN. That we don't add the `unoptimized` prop when
        // using the loader.
        loader={imageProps?.loader}
        className={cn(objectFit, className)}
        fill
        sizes="100vw"
        style={{
          objectPosition,
        }}
        alt={altText}
        {...rest}
      />
    );
  }

  return (
    <Image
      {...imageProps}
      className={cn(objectFit, 'h-auto w-auto', heightClass, className)}
      sizes="100vw"
      alt={altText}
      {...rest}
    />
  );
};

export default SanityImage;
