import { Router, useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useGlobalConfig } from '../../../hooks/configContext';
import { createQueryParamsFromMatchedPartner } from '../../../lib/handleInterestCreated/partnerQueryParamHelpers';
import { Cloud$BusinessUnitConfig } from '../../../types/cloudApi';

export function constructDestinationUrl(
  config: Cloud$BusinessUnitConfig,
  locale: string,
  query: Router['query'],
) {
  const baseUrl = `${config.BREEZE_REDIRECT_BASE_URL}/${locale}/solar/redirect/:id`; // `:id` is intentionally a placeholder and will be replaced by Cloud before redirecting

  const { partner = null, utm_source: utmSource = null } = {
    ...query,
    ...createQueryParamsFromMatchedPartner(config.matchedPartner),
  };

  const searchParams =
    partner || utmSource
      ? `?${new URLSearchParams({
          partner: partner || utmSource,
        }).toString()}`
      : '';

  return `${baseUrl}${searchParams}`;
}

/**
 * Generates the appropriate destination URL for submitting an interest.
 * Once the interest is successfully created, Cloud responds with an HTTP 302, directing the browser to the "Destination URL" crafted by this function.
 * The destination could be the standard Breeze domain (e.g., solar.otovo.com) or a specific reseller/platform partner's subdomain (e.g., foopartner.otovo.com).
 *
 * Because the iframe might be statically generated, we need to resolve this on the client side to get the query param.
 */

export function useDestinationUrl() {
  const [nextUrl, setNextUrl] = useState('');
  const { BU_CONFIG } = useGlobalConfig();
  const { locale, query } = useRouter();
  useEffect(() => {
    const url = constructDestinationUrl(BU_CONFIG, locale, query);

    setNextUrl(url.toString());
  }, [BU_CONFIG, locale, query]);

  return nextUrl;
}
