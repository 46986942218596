import { getCookieValue } from '../cookieUtils';
import { POSTHOG_COOKIE_NAME } from './constants';

export function convertPosthogCookieToSearchParams() {
  const postHogSearchParams = new URLSearchParams();
  const postHogCookieValue = getCookieValue(POSTHOG_COOKIE_NAME);

  if (postHogCookieValue) {
    // Decode PostHog cookie to prevent double encoding in search params
    const decodedValue = decodeURIComponent(postHogCookieValue);
    postHogSearchParams.set(POSTHOG_COOKIE_NAME, decodedValue);
    return postHogSearchParams;
  }
  return null;
}
