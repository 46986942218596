import { storeInterest } from '../../components/InterestRegistration/storeInterestUtils';
import { Cloud$Interest } from '../../types/cloudApi';
import { Otovo$Locale } from '../../types/otovoweb';
import { logInterest } from '../analytics/analytics';
import { getClientIdFromTracker } from '../analytics/gtm';
import { getCookieSearchParams } from '../cookieUtils';
import { convertPosthogCookieToSearchParams } from '../posthog/convertPosthogCookieToSearchParams';
import throwForFalsy from '../throwForFalsy';

import appendAndClickLink from './appendAndClickLink';

type CreateHrefArgs = {
  locale: Otovo$Locale;
  id: string;
  queryParams?: Record<string, string>;
  redirectBaseUrl: string;
};

export function createRedirectHref({
  locale,
  id,
  queryParams,
  redirectBaseUrl,
}: CreateHrefArgs): string {
  const baseUrl = `${throwForFalsy(
    redirectBaseUrl,
  )}/${locale}/solar/redirect/${id}`;

  const clientId = getClientIdFromTracker();
  const cookieSearchParams = getCookieSearchParams();
  const postHogSearchParams = convertPosthogCookieToSearchParams();

  try {
    const url = new URL(baseUrl);

    if (clientId) {
      url.searchParams.append('gaClientId', clientId);
    }

    if (queryParams) {
      new URLSearchParams(queryParams).forEach((value, key) =>
        url.searchParams.append(key, value),
      );
    }

    if (cookieSearchParams) {
      cookieSearchParams.forEach((value, key) =>
        url.searchParams.append(key, value),
      );
    }

    if (postHogSearchParams) {
      postHogSearchParams.forEach((value, key) =>
        url.searchParams.append(key, value),
      );
    }

    return url.href;
  } catch (error) {
    // return the base url if the new URL is invalid, this happens in the test environment
    return baseUrl;
  }
}

function storeForWelcomeBack(interest: Cloud$Interest) {
  storeInterest(interest);
}

function redirectToSalesFlow(
  interestId: string,
  locale: Otovo$Locale,
  redirectBaseUrl: string,
  queryParams?: Record<string, string>,
) {
  const href = createRedirectHref({
    locale,
    id: interestId,
    queryParams,
    redirectBaseUrl,
  });

  appendAndClickLink(href);
}

// Default handling when a interest is created
export function handleInterestCreated({
  interest,
  locale,
  redirectBaseUrl,
  queryParams,
}: {
  interest: Cloud$Interest;
  locale: Otovo$Locale;
  redirectBaseUrl?: string;
  queryParams?: Record<string, string>;
}): void {
  logInterest({ locale });
  storeForWelcomeBack(interest);
  if (redirectBaseUrl) {
    redirectToSalesFlow(interest.id, locale, redirectBaseUrl, queryParams);
  }
}
