import { useTheme } from '@emotion/react';
import { ComboboxOption, ComboboxOptions, Transition } from '@headlessui/react';
import cx from 'classnames';
import { CSSProperties } from 'react';

import { Cloud$SuggestedAddress } from '../../../types/cloudApi';

type Props = {
  suggestions: Array<Cloud$SuggestedAddress>;
  query: string;
};

const StyledAddressSuggestions = ({ suggestions, query }: Props) => {
  const theme = useTheme();
  // Workaround to using our aliased theme together with tailwind arbitrary values.
  // Used in conjunction with bg-[var(--active-color)]
  const optionStyles = {
    '--active-color': theme.alias.background_200,
  } as CSSProperties;

  return (
    <Transition
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      static
    >
      <ComboboxOptions
        as="ul"
        data-testid="address-search-suggestions"
        modal={false}
        static
        className={cx(
          'absolute w-full overflow-auto rounded-b-lg bg-white ',
          'z-50 text-base shadow-lg ring-1 ring-black ring-opacity-5 md:text-lg',
          {
            'mt-1': suggestions.length || (!suggestions.length && query.trim()),
          },
        )}
      >
        {!!suggestions.length && (
          <div className="py-2">
            {suggestions.map((suggestion, i) => (
              <ComboboxOption
                as="li"
                data-testid={`suggestion-${i}`}
                key={suggestion.id}
                style={optionStyles}
                className={({ focus }) =>
                  cx(
                    'relative cursor-default select-none px-4 py-3',
                    focus && 'bg-[var(--active-color)]',
                  )
                }
                value={suggestion}
              >
                <span className="block truncate">{suggestion.text}</span>
              </ComboboxOption>
            ))}
          </div>
        )}
        {!suggestions.length && query.trim() && (
          <div className="py-2">
            <ComboboxOption
              as="li"
              value={{ id: '', text: query }}
              style={optionStyles}
              className={({ focus }) =>
                cx(
                  'relative cursor-default select-none px-4 py-3',
                  focus && 'bg-[var(--active-color)]',
                )
              }
            >
              <span className="block truncate">{query}</span>
            </ComboboxOption>
          </div>
        )}
      </ComboboxOptions>
    </Transition>
  );
};

export default StyledAddressSuggestions;
