import log from 'loglevel';
import { logUIEvent } from '../../lib/analytics/analytics';

const experiments = {};

export const sendWinIfPlayed = (experimentName: string) => {
  const experiment = experiments[experimentName];

  if (!experiment) {
    log.info(`Cannot log a win for unplayed experiment ${experimentName}`);
    return;
  }

  logUIEvent(
    {
      action: 'win',
      context: [experimentName, experiment.variantValue]
        .filter(Boolean)
        .join('-'),
    },
    { once: true },
  );
};

export const sendPlay = (experimentName: string, variantValue: string) => {
  const played = !!experiments[experimentName];
  if (played) {
    return;
  }

  experiments[experimentName] = { variantValue };

  logUIEvent(
    {
      action: 'play',
      context: [experimentName, variantValue].filter(Boolean).join('-'),
    },
    { once: true },
  );
};
