const LEADING_SLASH = /^\//;
const TRAILING_SLASH = /\/+$/;

export function stripLeadingSlash(value = '') {
  return value.replace(LEADING_SLASH, '');
}

export function stripTrailingSlash(value = '') {
  return value.replace(TRAILING_SLASH, '');
}
