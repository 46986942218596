import { handleResponseErrors } from '../ErrorHandling/handleResponseErrors';

import {
  Cloud$BusinessUnitConfig,
  Cloud$SuggestedAddress,
} from '../../../../types/cloudApi';
import { AddressNotFoundError } from '../ErrorHandling/AddressNotFoundError';
import { MIN_CHARS_FOR_SENDING_REQUEST } from '../constants';

type ValidationReponse = {
  status: string;
  message: string;
  address: Cloud$SuggestedAddress;
};

export async function validateOnServer(
  address: Cloud$SuggestedAddress,
  buSlug: Cloud$BusinessUnitConfig['slug'],
  market: Cloud$BusinessUnitConfig['market'],
) {
  const baseUrl = new URL(
    `${process.env.NEXT_PUBLIC_OTOVOAPI_URL}/web/location/address/validate/`,
  );
  const arg = {
    id: address.id,
    text: address.text,
    business_unit: buSlug,
    country: market,
  };

  const result = await fetch(baseUrl.toString(), {
    method: 'post',
    body: JSON.stringify(arg),
    headers: { 'content-type': 'application/json' },
  });

  handleResponseErrors(result);

  const { address: validAddress }: ValidationReponse = await result.json();

  return validAddress;
}

/**
 * Validate the address (or first suggestion) and return array with [address, error]
 */
export function validateOnClient(
  address: Cloud$SuggestedAddress,
  suggestions: Cloud$SuggestedAddress[] | null,
  freeTextSearchEnabled: boolean,
): Cloud$SuggestedAddress {
  if (!address.text || address.text.length < MIN_CHARS_FOR_SENDING_REQUEST) {
    throw new AddressNotFoundError(
      'Client side validation: Address is missing <text>',
    );
  }

  if (address.id) {
    return address;
  }

  if (
    freeTextSearchEnabled &&
    address.text.length >= MIN_CHARS_FOR_SENDING_REQUEST
  ) {
    return address;
  }

  // Pick the first address from suggestions
  const firstSuggestion = suggestions?.length ? suggestions[0] : null;

  if (!firstSuggestion || !firstSuggestion?.id) {
    throw new AddressNotFoundError(
      'Client side validation: First suggestion is missing <id>',
    );
  }
  return firstSuggestion;
}
