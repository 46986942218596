import { ButtonHTMLAttributes } from 'react';
import Spinner from '../../components/LoadingScreens/Spinner';
import { cn } from '../../lib/classNames';

type ButtonProps = {
  loading?: boolean;
  className?: string;
  shape?: 'circle';
  variant?: 'blue' | 'peach' | 'darkGrey';
} & ButtonHTMLAttributes<HTMLButtonElement>;

const Button = ({
  loading,
  children,
  shape,
  variant,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      type="button"
      className={cn(
        'rounded-full px-3.5 py-2 text-base font-medium outline-none',
        'focus:ring-1 focus:ring-grey-90',
        'disabled:bg-grey-10 disabled:text-muted',
        shape === 'circle'
          ? 'flex items-center justify-center p-3.5 text-lg'
          : '',
        variant === 'blue' && 'bg-blue-50 text-white hover:bg-blue-60',
        variant === 'peach' && 'bg-peach-50 hover:bg-peach-60',
        variant === 'darkGrey' && 'bg-grey-90 text-lilac-20 hover:bg-grey-100',

        className,
      )}
      {...props}
    >
      {loading ? (
        <Spinner
          size="w-5"
          color={cn(
            variant === 'blue' && 'text-white',
            variant === 'peach' && 'text-grey-90',
            variant === 'darkGrey' && 'text-lilac-20',
          )}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
