import { MessageDescriptor } from 'react-intl';
import messages from '../../../../../../breeze/components/Interest/messages';
import { logSentryError } from '../../../../lib/sentry';
import { AddressNotFoundError } from './AddressNotFoundError';
import { AreaTooBigError } from './AreaTooBigError';

/**
 * Returns an intl error message descriptor based on the type of error passed in.
 */
export function getMessageForFetchError(
  createInterestError: Error | unknown,
): MessageDescriptor {
  // Check the type of error and return the appropriate error message
  if (createInterestError instanceof AreaTooBigError) {
    return messages.areaOfInterestTooLarge;
  }
  if (createInterestError instanceof AddressNotFoundError) {
    return messages.addressNotFound;
  }
  // If the error type is unknown, log it to Sentry and return a generic error message
  logSentryError(createInterestError);
  return messages.addressSuggestError;
}
