export class AreaTooBigError extends Error {
  status: number;

  __proto__ = Error;

  constructor(message: string, status: number) {
    super(message);
    this.status = status;
    Object.setPrototypeOf(this, AreaTooBigError.prototype);
  }
}
