import { memo } from 'react';
import { isEnabled } from '../../config/featureToggles/utils';
import { useGlobalConfig } from '../../hooks/configContext';
import { Otovo$Config } from '../../types/otovoweb';
import { sendPlay } from './utils';

type Props = {
  featureToggleName: keyof Otovo$Config['FEATURE_TOGGLES'];
  children?: JSX.Element;
};

const IfEnabled = ({ featureToggleName, children }: Props) => {
  const config = useGlobalConfig();

  if (isEnabled(featureToggleName, config)) {
    sendPlay(featureToggleName, 'enabled');
    return children;
  }
  return null;
};

IfEnabled.displayName = 'IfEnabled';

export default memo<Props>(IfEnabled);
